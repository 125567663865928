<template>
  <div class="register">
    <loginHeader></loginHeader>
    <div class="register-body" :class="clientWidth > 750 ? 'flex3' : ''">
      <div class="register-content" :class="clientWidth > 750 ? 'flex0' : ''">
        <div class="register-detail">
          <div class="tc">
            <img src="@/assets/PC/header/logo_slices/logo.png" alt="" style="padding: 20px 0">
          </div>
          <el-form :model="registerForm" :rules="rules" ref="registerForm" class="registerForm">
            <p style="line-height: 40px" class="">已有账号？输入AVEVA登录账号立即绑定</p>
            <el-form-item prop="phone">
              <el-input v-model="registerForm.phone" placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item prop="code">
              <div class="flex2">
                <el-input v-model="registerForm.code" placeholder="请输入验证码" style="margin-right: 5px" maxlength="6"></el-input>
                <el-button class="getCode" v-if="codeShow" @click="getPhoneCode">获取验证码</el-button>
                <el-button class="getCode" v-else disabled>{{ count }} 秒后重试</el-button>
              </div>
            </el-form-item>
            <el-form-item class="loginStateBox">
                <el-checkbox v-model="registerForm.loginState">保持登录</el-checkbox>
              </el-form-item>
            <el-form-item>
              <el-button type="info" size="medium" :loading="loading" @click="submitForm('registerForm')" class="pc-register loginBtn">授权并登录</el-button>
            </el-form-item>
            <div class="flex6">
              <span class="CancelHome link" @click="goHome">返回首页</span>
              <span class="violet cur" @click="goRegister">我没有账号，立即注册 <i class="el-icon-d-arrow-right"></i></span>
            </div>
          </el-form>
        </div>
      </div>
    </div>
    <login-alert></login-alert>
  </div>
</template>

<script>
import LoginAlert from "@/components/LoginAlert/index.vue";
import loginHeader from "@/components/loginHeader.vue";
import { getCode } from "@/api/register.js";
import { debounce, Login_GetInfoDetails } from "@/utils/common";
import { dataState } from "@/api/all.js";
import { BindPhone } from "@/api/user.js";
import { mapState } from 'vuex';

export default {
  name: "LoginWXgo",
  data() {
    return {
      codeShow: true,                         // !获取验证码选中状态
      loading: false,                         // !登录loading
      count: "",                              // !验证码状态
      registerForm: {
        phone: "",                            // !手机号
        code: "",                             // !验证码
        uuid: this.$route.query.uuid ? this.$route.query.uuid : "",
        loginState:false
      },
      rules: {
        phone: [
          { required: true, message: "请输入您的手机号", trigger: "blur" },
          {
            pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
    };
  },
  mounted() {
    if(this.$store.state.clientWidth < 750) {
      this.$router.push('/Login');
    }
    // 数据统计
    const data = {
      visitType: 1,
      gid: sessionStorage.getItem("gid") ? sessionStorage.getItem("gid") : "",
      url: window.location.href,
      pageUrl: "LoginWXgo",
      relationId: "",
    };
    dataState(data).then((res) => {
      sessionStorage.setItem("gid", res.data);
    });
  },
  methods: {
    // !绑定账户
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          BindPhone(this.registerForm).then(res => {
            if(res.status == 1) {
              this.$store.commit("$_setStorage", res.data);
              this.$toast.success("绑定成功！");
              setTimeout(() => {
                Login_GetInfoDetails(this.$route)
              }, 800);
            } else {
              this.$toast(res.message);
              this.loading = false;
            }
          })
        }
      });
    },
    // !点击获取验证码
    getPhoneCode() {
      const phone = this.registerForm.phone;
      if (!phone) {
        this.$toast({ message: "请先输入您的手机号！" });
        return false;
      } else if (!validator(phone)) {
        this.$toast({ message: "请输入正确的手机号！" });
        return false;
      } else {
        debounce(() => {
          getCode({ phone: phone, type: 4 }).then((res) => {
            if (res.status == 1) {
              this.$toast("发送成功！");
              const TIME_COUNT = 60;
              if (!this.timer) {
                this.count = TIME_COUNT;
                this.codeShow = false;
                this.timer = setInterval(() => {
                  if (this.count > 0 && this.count <= TIME_COUNT) {
                    this.count--;
                  } else {
                    this.codeShow = true;
                    clearInterval(this.timer);
                    this.timer = null;
                  }
                }, 1000);
              }
            } else {
              this.$toast(res.message);
            }
          });
        }, 1000);
      }
      // 正则验证格式
      function validator(value) {
        var reg =
          /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
        return RegExp(reg).test(value);
      }
    },
    // !去注册
    goRegister() {
      this.$router.push('/Register?uuid='+ this.registerForm.uuid);
    },
    // !去首页
    goHome() {
      if(this.$route.query.from || (this.channel === 1 || this.$route.query.from)) {
        this.$router.push('/Home/Live')
      }else {
        this.$router.push('/')
      }
    },
  },
  computed: {
    ...mapState(['clientWidth'])
  },
  components: {
    loginHeader,
    LoginAlert
  },
};
</script>

<style lang="scss">
$mobil_base: 750;
@function vw($true) {
  @return ($true / $mobil_base) * 100vw;
}

.register {
  height: 100vh;
}


@media (min-width: 750px) {
  .loginStateBox {
    margin-bottom: 10px;
    .el-checkbox__input{
      margin-bottom: 1px;
    }
  }
  .register {
    background: #f7f7f7;
    .register-body { padding: 15px 0; min-height: calc(100vh - 83px); background: #f7f7f7 }
    .register-content { width: 100%; max-width: 1200px; margin: 0 auto }
    .register-detail { box-shadow: 0 0 10px #ccc }
    .register-logo img { width: 589px }
    .register-body {
      .register-detail {
        padding: 15px 0 30px 0;
        width: 480px;
        background: #fff;
        h1 {
          font-size: 24px;
          font-weight: bold;
          color: #363d42;
          line-height: 46px;
          text-align: center;
          margin-bottom: 15px;
        }
        .registerForm {
          width: 420px;
          margin: 0 auto;
          .form-item {
            &.code_input {
              /deep/ .el-form-item__content {
                display: flex;
                justify-content: space-between;
                .el-input {
                  width: 150px;
                }
                .el-button {
                  width: 120px;
                  &:hover {
                    background: #fff;
                    color: #5f1593;
                  }
                }
              }
            }
          }
        }
      }
    }
    
  }
}

</style>
